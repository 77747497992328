<script>
export default {
  data() {
    return {
    };
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          id: null,
          link: '',
          title: '',
          memo: '',
          urlAvatar: '',
          urlCover: ''
        }
      }
    }
  },
  components: {
  },
};
</script>

<template>
  <div class="card">
    <div class="row g-0">
      <div class="col-md-4">
        <router-link :to="{ name: 'article', params: { blogLink: this.$route.params.blogLink, articleLink: this.data.link } }">
          <img class="rounded-start img-fluid h-150 object-cover" v-bind:src="data.urlAvatar" v-bind:alt="data.title" v-if="data.urlAvatar">
        </router-link>
      </div>
      <div class="col-md-8">
        <div class="card-header">
          <router-link :to="{ name: 'article', params: { blogLink: this.$route.params.blogLink, articleLink: this.data.link } }">
            <h5 class="card-title mb-0">{{ data.title }}</h5>
          </router-link>
        </div>
        <div class="card-body">
          <p class="card-text mb-2">{{  data.memo  }}</p>
          <div class="text-start">
            <small class="text-muted">Last updated 23 mins ago</small>
          </div>
          <div class="text-end">
            <router-link :to="{ name: 'article', params: { blogLink: this.$route.params.blogLink, articleLink: this.data.link } }" class="link-primary fw-medium">
              Read More <i class="ri-arrow-right-line align-middle"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>