<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/PageHeader.vue";
import appConfig from "../../../app.config";

import axios from 'axios';
import ArticleItem from "./ArticleItem.vue";

export default {
  page: {
    title: "Blog",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Blog",
      description: "Official team blog",
      data: {
        posts: [],
        blog: {
        }
      }
    };
  },
  components: {
    Layout,
    PageHeader,
    ArticleItem,
  },
  created: function () {
    this.loadingBlog();
  },
  methods: {
    loadingBlog() {
      axios.get('/api/blog/' + this.$route.params.blogLink)
        .then((response) => {
          this.data = response.data;
        })
        .catch(function () {
        });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :description="description" />
    
    <div class="row">
      <div class="col-xxl-6" v-for="p in data.posts" v-bind:key="p">
        <ArticleItem v-bind:data="p"></ArticleItem>
      </div>
    </div>

  </Layout>
</template>